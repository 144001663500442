angular
    .module('app')
    .component('step1Component', {
        templateUrl: 'app/components/wizard/step1.component.html',
        controller: Step1
    });

/** @ngInject */
function Step1($rootScope, $state, wizardService, $cookies, $window, $location) {
    this.rs = $rootScope;
    this.state = $state;
    this.wizardService = wizardService;
    this.cookies = $cookies;
    this.invalidEmail = false;
    this.showSpinner = false;
    this.location = $location;
    // patterns for validates inputs text
    this.phonePattern = "\\d+";
    this.emailPattern = /^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i;
    // get device details
    this.rs.lead.device_details = JSON.stringify(getDetails());
    this.rs.lead.user_agent = getDetails()['Full User Agent'];
    this.rs.lead.window_size = getDetails()['Screen Size'];
    this.rs.lead.is_mobile = getDetails()['Mobile'];
    this.rs.lead.entry_url = this.cookies.get('entry_url');
    this.window = $window;
}

Step1.prototype = {
    // this function restrict only numbers in input text
    onlyNumers: function(event) {
        const pattern = /[0-9\+\-\ ]/;
        var inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    },

    changeToStep2: function() {
        // redefine this context
        var self = this;
        self.rs.lead.has_credit = true;
        self.rs.lead.good_payer = false;
        // validate if the lead has already sent a request to validate mail
        if (self.rs.lead.id) {
            self.state.go('step2');
        } else {

            this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));

            // add utm_source and SID to lead main object
            this.rs.lead.utm_source = this.campaign_params.utm_source;
            this.rs.lead.sid = this.campaign_params.SID;
            this.rs.lead.sub_id = this.campaign_params.SUB_ID;
            this.rs.force = this.campaign_params.force;

            // show ajax spinner
            self.showSpinner = true;

            self.wizardService.getIpClient().then(function(clientData) {
                // set client ip
                self.rs.lead.user_ip = clientData.data.ip;
                self.rs.lead.application_path = 'TOP_TARJETAS_CO';
                // Create lead and verify duplicated
                
                self.wizardService.getAffilate(self.rs.lead.utm_source)
                .then(function(aff){
                    self.rs.affExt = aff.detail.type == 'INTERNAL' ? false : true;
                    self.rs.lead.loan = 0;
                    self.rs.lead.return_days = 80;
                    if(self.rs.params_extend.t_num == "")
                        self.rs.params_extend.t_num = self.rs.lead.mobile_number
                    if(self.rs.params_extend.t_email == "")
                        self.rs.params_extend.t_email = self.rs.lead.email

                    self.wizardService.createFullLead(self.rs.lead).then(function(createdLead) {
                        // save lead id to update it later 
                        self.rs.lead.id = createdLead.id;
                        var absUrl = self.location.absUrl();
                        // self.window.gtag_report_conversion(absUrl)
                        self.showSpinner = false;
                        self.window.dataLayer.push({
                            'event': 'lead_general',
                            'id_lead': createdLead.id,
                            'mobile_number': self.rs.lead.mobile_number,
                            'email': self.rs.lead.email,
                            'sub_id': self.rs.lead.sub_id
                        });
                        if (createdLead.duplicated) {
                            self.rs.duplicated = createdLead.duplicated;
                            self.window.dataLayer.push({
                                'event': 'lead_duplicated',
                                'id_lead': createdLead.id,
                                'mobile_number': self.rs.lead.mobile_number,
                                'email': self.rs.lead.email,
                                'sub_id': self.rs.lead.sub_id
                            });
                            // show duplicated pixel
                            // self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=432856&stepId=4392&MerchantReferenceID=" + self.rs.lead.mobile_number + "_" + self.rs.lead.email + "_track";
                        } else {
                            self.window.dataLayer.push({
                                'event': 'lead_new',
                                'id_lead': createdLead.id,
                                'mobile_number': self.rs.lead.mobile_number,
                                'email': self.rs.lead.email,
                                'sub_id': self.rs.lead.sub_id
                            });
                            // show normal pixel
                            // self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=432856&MerchantReferenceID=" + self.rs.lead.mobile_number + "_" + self.rs.lead.email + "_track&Savecookie=X";
                            
                        };
                        self.state.go('step2');
                    })
                })
            });
        }
    }
};