angular
    .module('app')
    .component('thankyouComponent', {
        templateUrl: 'app/components/thankyou/thankyou.component.html',
        controller: ThankyouInfo
    });

/** @ngInject */
function ThankyouInfo($scope, $rootScope, $stateParams, $state, $window, $timeout) {
    this.scope = $scope;
    this.rs = $rootScope;
    this.sp = $stateParams;
    this.state = $state;
    this.window = $window;
    $scope.redirect = this.sp.redirect;
    this.timeout = $timeout;
    $scope.lender = this.sp.lender;
    const utm = this.rs.lead.utm_source;
    const sid = this.rs.lead.sid !== '' ? this.rs.lead.sid : 'unassigned';
    if($scope.lender == 'Tuya'){
        $scope.impressionPixel = `https://ojo7.ltroute.com/impression.track?CID=434374&AFID=${utm}&SID=ToptarjetasCo-${sid}`;
    }else if($scope.lender == 'Lineru'){
        $scope.impressionPixel = `https://ojo7.ltroute.com/impression.track?CID=431739&AFID=${utm}&SID=ToptarjetasCo-${sid}`;
    }else{ // RapiCredit
        $scope.impressionPixel = `https://ojo7.ltroute.com/impression.track?CID=431738&AFID=${utm}&SID=ToptarjetasCo-${sid}`;
    }
    $scope.pixel = '';
    if(!this.rs.duplicated && this.rs.affExt){
        if(this.rs.lead.monthly_salary > 1799999 && this.rs.lead.has_bank_account == 1 && this.rs.lead.bank != '' && this.rs.lead.bank != 'otro'){
            $scope.pixel = "https://ojo7.ltroute.com/pixel.track?CID=432856&MerchantReferenceID=" + this.rs.lead.mobile_number + "_" + this.rs.lead.email + "_track&Savecookie=X";
        }else{
            $scope.pixel = "https://ojo7.ltroute.com/pixel.track?CID=432856&stepId=4915&MerchantReferenceID=" + this.rs.lead.mobile_number + "_" + this.rs.lead.email + "_track";
        }   
    }
    this.timeout(function(){ 
        $window.location.href = $stateParams.redirect;
    }, 5000);
}

ThankyouInfo.prototype = {}