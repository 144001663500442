angular
    .module('app')
    .component('thankyouCoruComponent', {
        templateUrl: 'app/components/thankyou/thankyoucoru.component.html',
        controller: ThankyouCoru
    });

/** @ngInject */
function ThankyouCoru($rootScope, $stateParams, $state, $window, $timeout) {
    this.rs = $rootScope;
    this.sp = $stateParams;
    this.state = $state;
    this.window = $window;
    this.coru = this.sp.coru;
    this.timeout = $timeout;
    this.showSpinner = true;

    if (Object.keys(this.coru).length < 1){
        this.state.go('app');
    }
    var self = this;
    // self.window.obApi('track', 'Tarjetas Enviado a Coru');
    this.timeout(function(){ 
        self.window.location.href = self.sp.coru.href;
        self.showSpinner = false;
    }, 5000);

    // clear lead object to avoid browser back action
    this.rs.lead = {
        name: '',
        lastname: '',
        email: '',
        mobile_number: '',
        has_credit: '',
        good_payer: '',
        monthly_income: '',
        interest: '',
        accept_terms: false,
        accept_privacy: false,
        device_details: ''
    }

    // this.window.dataLayer.push({
    //     'event': 'Pageview_Coru',
    //     'pagePath': '/thankyou_coru',
    //     'pageTitle': 'Thank you coru' //some arbitrary name for the page/state
    // });
    // this.window.ga('send', 'pageview', '/thankyou_coru');
    // this.window.fbq('track', 'ViewContent');
}

ThankyouCoru.prototype = {
}