angular
  .module('app')
  .component('aztecaProductComponent', {
    templateUrl: 'app/components/azteca/aztecaProduct/aztecaProduct.component.html',
    controller: AztecaProduct
  });

/** @ngInject */
function AztecaProduct($uibModal, $stateParams, $state, $cookies, productService, $window, wizardService) {
  this.$onInit = function () {
    this.uibModal = $uibModal;
    this.cookies = $cookies;
    this.sp = $stateParams;
    this.state = $state;
    this.productService = productService;
    this.wizardService = wizardService;
    this.product = this.sp.product;
    this.window = $window;
    this.productObj = {};   
    this.trackPixel = '';
    this.showSpinner = false;
    var self = this;

    if(self.sp.email && parseInt(self.sp.email) == 1){
      self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&stepId=2615&MerchantReferenceID=";
    }

    // create campaign_params json for save it in the cookies for linktrust pixels
    self.campaign_params = {
      utm_source: self.validate_utm_source(self.sp.utm_source) ? self.sp.utm_source : '418761',
      sub_id: self.generate_sub_id(self.sp.utm_source, self.sp.sub_id),
      SID: self.sp.SID ? self.sp.SID : "",
      utm_medium: self.sp.utm_medium ? self.sp.utm_medium : "",
      utm_campaign: self.sp.utm_campaign ? self.sp.utm_campaign : "",
      utm_term: self.sp.utm_term ? self.sp.utm_term : "",
      utm_content: self.sp.utm_content ? self.sp.utm_content : "",
      utm_nooverride: self.sp.utm_nooverride ? self.sp.utm_nooverride : "",
      click_id: self.sp.click_id ? self.sp.click_id : ""
    }

    // remove the campaign parameters if it is in cookies
    if (!self.cookies.get('campaign_params'))
      self.cookies.remove('campaign_params');
    // set a new cookie
    self.cookies.put('campaign_params', JSON.stringify(self.campaign_params), { secure: false, samesite: 'strict'});
    if (!self.cookies.entry_url)
      self.cookies.put('entry_url', self.window.location.href, { secure: false, samesite: 'strict'});

  }
}



AztecaProduct.prototype = {

  openModal: function () {
    // open modal dialog
    var self = this;
    self.showSpinner = true;
    const em = self.sp.email;
    if(em & parseInt(em) == 1){
        const deviceDetails = getDetails();
        if(deviceDetails.OS == 'iOS'){
            self.wizardService.getLink('430147', self.campaign_params.utm_source, self.sp.SID)
            .then(function(result){
                window.location.href = result.detail;
                self.showSpinner = false;
            })
        }else{
            self.wizardService.getLink('430148', self.campaign_params.utm_source, self.sp.SID)
            .then(function(result){
              window.location.href = result.detail;
              self.showSpinner = false;
            })
        }
    }else{
      self.showSpinner = false;
      var modalInstance = self.uibModal.open({
        animation: true,
        component: 'modalFormAztecaComponent',
        windowClass: 'modalWindowClass',
        backdropClass: 'zindex2',
        resolve: {
          product: function () {
            return self.productObj;
          }
        }
      });
      // handle the buttons result
      modalInstance.result.then(
        function ok() {
          console.log('ok');
        },
        function cancel() {
          console.log('cancel');
        }
      );
    }
  },

  isInteger: function (data) {
    try {
      if (typeof data === 'number') {
        var remainder = data % 1
        if (remainder === 0) {
          return true
        }
      }
      return false
    } catch (err) {
      return false
    }
  },

  validate_utm_source: function (utm_source) {
    // validates if utm_source is integer
    if (!this.isInteger(utm_source * 1)
      || utm_source * 1 < 418761) {
      // set utm_source for organic origin (does not belong to a valid campaign )
      return false;
    }
    return true;
  },

  generate_sub_id: function (utm_source, sub_id) {
    // if utm_source isn't valid and sub_id is empty the sub_id becomes
    // in the original utm_source
    if (!this.validate_utm_source(utm_source)) {
      // utm_source no valid, campaign no valid
      if (sub_id == '' || typeof sub_id === 'undefined') {
        // if sub_id empty or undefined return utm_source
        if (utm_source == '' || typeof utm_source === 'undefined') {
          return '';
        }
        return utm_source;
      } else {
        // if sub_id is full return sub_id
        return sub_id;
      }
    } else {
      // utm_source valid
      if (sub_id == '' || typeof sub_id === 'undefined') {
        // if sub_id empty or undefined return empty string
        return "";
      } else {
        // if sub_id is full return sub_id
        return sub_id;
      }
    }

  }
}