angular
  .module('app')
  .component('step3Component', {
    templateUrl: 'app/components/wizard/step3.component.html',
    controller: Step3
  });

/** @ngInject */
function Step3($rootScope, $state, $window) {
  this.rs = $rootScope;
  this.state = $state;
  this.window = $window;
  if(!this.rs.lead.id){
    this.state.go('app');
  }
  // this.window.dataLayer.push({
  //   'event': 'Pageview_TOP',
  //   'pagePath': '/step2',
  //   'pageTitle': 'Step2' //some arbitrary name for the page/state
  // });
  // this.window.ga('send', 'pageview', '/step2');
  // this.window.fbq('track', 'ViewContent');
  console.log('WINDOW:: ', this.window);
}

Step3.prototype = {
  changeToStep4 : function(){
      this.state.go('step4');
  }
};