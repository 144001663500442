angular
  .module('app')
  .component('mainSection', {
    templateUrl: 'app/components/mainSection/mainSection.component.html',
    controller: MainSection
  });

/** @ngInject */
function MainSection($stateParams, $localStorage, $scope, $rootScope, $cookies, $window, wizardService) {
  // init objects
  this.sp = $stateParams;
  this.rs = $rootScope;
  this.cookies = $cookies;
  this.window = $window;
  this.wizardService = wizardService;
  $scope.$storage = $localStorage;

  let utm_source = '';
  let SID = '';
  if(this.sp.utm_source === undefined && this.sp.gclid){
    SID = 'gclid';
    utm_source = '425145';
  }else{
    SID = this.sp.SID
    utm_source = this.sp.utm_source
  }

  // create campaign_params json for save it in the cookies for linktrust pixels
  this.campaign_params = {
    utm_source: this.validate_utm_source(utm_source) ? utm_source : '418761',
    sub_id: this.generate_sub_id(this.sp.utm_source, this.sp.sub_id),
    SID: SID ? SID : "unassigned",
    utm_medium: this.sp.utm_medium ? this.sp.utm_medium : "",
    utm_campaign: this.sp.utm_campaign ? this.sp.utm_campaign : "",
    utm_term: this.sp.utm_term ? this.sp.utm_term : "",
    utm_content: this.sp.utm_content ? this.sp.utm_content : "",
    utm_nooverride: this.sp.utm_nooverride ? this.sp.utm_nooverride : "",
    click_id: this.sp.click_id ? this.sp.click_id : "",
    force: this.sp.force ? this.sp.force : "",
  }

  this.params_extend = {
    t_num: this.sp.t_num ? this.sp.t_num : "",
    t_id: this.sp.t_id ? this.sp.t_id : "",
    t_email: this.sp.t_email ? this.sp.t_email : "",
    origin: this.sp.origin ? this.sp.origin : "",
    sm_datetime: this.sp.sm_datetime ? this.sp.sm_datetime : "",
    AffiliateReferenceID: this.sp.click_id ? this.sp.click_id : "",
  }

  const cp = this.campaign_params;
  const wp = _.find(_.values(cp), function(o){ return (o != '' && o != '418761')});
  this.rs.withParams = wp === undefined ? false : true;
  this.rs.params_extend = this.params_extend;
  
  // remove the campaign parameters if it is in cookies
  if (!this.cookies.get('campaign_params'))
    this.cookies.remove('campaign_params');
  // set a new cookie
  this.cookies.put('campaign_params', JSON.stringify(this.campaign_params), { secure: false, samesite: 'strict'});
  if (!this.cookies.entry_url)
    this.cookies.put('entry_url', this.window.location.href, { secure: false, samesite: 'strict'});
}

MainSection.prototype = {

  clickBanner: function(){
    const self = this;
    const extend_params = {
      t_num: self.campaign_params.t_num ? self.campaign_params.t_num : "",
      t_email: self.campaign_params.t_email ? self.campaign_params.t_email: "",
      sub_id: self.generate_sub_id(self.campaign_params.utm_source, self.campaign_params.sub_id),
      utm_medium: self.campaign_params.utm_medium ? self.campaign_params.utm_medium : "",
      utm_campaign: self.campaign_params.utm_campaign ? self.campaign_params.utm_campaign : "",
      utm_term: self.campaign_params.utm_term ? self.campaign_params.utm_term : "",
      utm_content: self.campaign_params.utm_content ? self.campaign_params.utm_content : "",
      utm_nooverride: self.campaign_params.utm_nooverride ? self.campaign_params.utm_nooverride : "",
      origin: self.campaign_params.origin ? self.campaign_params.origin : "",
      email_delivery_id: self.campaign_params.email_delivery_id ? self.campaign_params.email_delivery_id  : "",
      email_commission_campaign: self.campaign_params.email_commission_campaign ? self.campaign_params.email_commission_campaign : "",
      AffiliateReferenceId: self.generate_sub_id(self.campaign_params.utm_source, self.campaign_params.sub_id),
      affRefCidOrigin: self.campaign_params.affRefCidOrigin ? self.campaign_params.affRefCidOrigin : ""
    };
    self.wizardService.getLink('438630', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, extend_params) 
    .then(function(result){
        self.window.location.href = result.detail;
    })
  },

  isInteger: function (data) {
    try {
      if (typeof data === 'number') {
        var remainder = data % 1
        if (remainder === 0) {
          return true
        }
      }
      return false
    } catch (err) {
      return false
    }
  },

  validate_utm_source: function (utm_source) {
    // validates if utm_source is integer
    if (!this.isInteger(utm_source * 1)
      || utm_source * 1 < 418761) {
      // set utm_source for organic origin (does not belong to a valid campaign )
      return false;
    }
    return true;
  },

  generate_sub_id: function (utm_source, sub_id) {
    // if utm_source isn't valid and sub_id is empty the sub_id becomes
    // in the original utm_source
    if (!this.validate_utm_source(utm_source)) {
      // utm_source no valid, campaign no valid
      if (sub_id == '' || typeof sub_id === 'undefined') {
        // if sub_id empty or undefined return utm_source
        if (utm_source == '' || typeof utm_source === 'undefined') {
          return '';
        }
        return utm_source;
      } else {
        // if sub_id is full return sub_id
        return sub_id;
      }
    } else {
      // utm_source valid
      if (sub_id == '' || typeof sub_id === 'undefined') {
        // if sub_id empty or undefined return empty string
        return "";
      } else {
        // if sub_id is full return sub_id
        return sub_id;
      }
    }

  }

}
