angular
  .module('app')
  .component('step4Component', {
    templateUrl: 'app/components/wizard/step4.component.html',
    controller: Step4
  });

/** @ngInject */
function Step4($rootScope, $state, $window) {
  this.rs = $rootScope;
  this.state = $state;

  if(!this.rs.lead.id){
    this.state.go('app');
  }
  this.window = $window;
  // this.window.fbq('track', 'ViewContent');
}

Step4.prototype = {
  changeToStep5 : function(){
    this.state.go('step4');
    // this.window.fbq('track', 'CompleteRegistration');
  }
  
};