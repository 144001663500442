angular
  .module('app')
  .component('resultComponent', {
    // templateUrl: 'app/components/wizard/result.component.html',
    templateUrl: 'app/components/wizard/result.azteca.component.html',
    controller: Result
  });

/** @ngInject */
function Result($stateParams, $uibModal, $rootScope, $state, $window) {
  this.sp = $stateParams; 
  this.uibModal = $uibModal; 
  this.rs = $rootScope; 
  this.state = $state; 
  // get products from the stateParams
  this.products = this.sp.products; 
  this.lender = this.sp.lender;
  
  if(!this.rs.lead.id){ 
    this.state.go('app'); 
  } 
  this.window = $window;
  // this.window.fbq('track', 'ViewContent');
} 
 
Result.prototype = { 
 
  openModal: function (product) { 
    // open modal dialog 
    var modalInstance = this.uibModal.open({ 
      animation: true, 
      component: 'modalProductComponent', 
      windowClass: 'modalWindowClass', 
      backdropClass: 'zindex2', 
      resolve: { 
        product: function () { 
          return product; 
        } 
      } 
    }); 
 
    // handle the buttons result 
    modalInstance.result.then( 
      function ok(product) { 
         
      }, 
      function cancel() { 
 
      } 
    ); 
 
  }

};