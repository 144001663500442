angular
    .module('app')
    .service('wizardService', WizardService);

/** @ngInject */
function WizardService(Restangular, $q, $http) {
    this.restangular = Restangular;
    this.q = $q;
    this.http = $http;
}

WizardService.prototype = {

    createFullLead: function(lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        lead.country = 'CO';
        // create lead in full lead
        this.restangular.all('/lead/v2/')
        .post(lead).then(function(response) {
            // resolve promise 
            deferred.resolve(response.plain());
        });
        //return the promise
        return promise;
    },


    updateLead: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        // update shortLead to fullLead
        this.restangular.all('/toptarjetas/v2/lead/'+lead.id)
            .customPUT({
                id: lead.id,
                email: lead.email,
                mobile_number: lead.mobile_number,
                first_name: lead.first_name,
                last_name: lead.last_name,
                second_last_name: lead.second_last_name,
                birthdate: lead.birthdate,
                accept_terms: lead.accept_terms,
                accept_privacy: lead.accept_privacy,
                monthly_salary: lead.monthly_salary,
                has_credit_card: lead.has_credit_card,
                has_bank_account: lead.has_bank_account
            }).then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        //return the promise
        return promise;
    },

    getRecommendedProducts: function (monthly_income, interest, provider) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        this.restangular.one('toptarjetas', '')
            .one('product', monthly_income)
            .one('', interest)
            .one('', provider)
            .get()
            .then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        //return the promise
        return promise;
    },
    getProductXSell: function(product) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        this.restangular.all('toptarjetas/v2/product/'+product)
        .getList()
        .then(function (response) {
            // resolve the promise
            deferred.resolve(response.plain());
        })
        .catch(function(err){
            console.log('ERR::', err);
        });

        //return the promise
        return promise;
    },

    updateToFullLead: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;

        // create short lead
        this.restangular.all('/toptarjetas/lead/complete')
            .post({
                id: lead.id,
                name: lead.name,
                lastname: lead.lastname,
                status: 'full_lead'
            }).then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        //return the promise
        return promise;
    },

    getIpClient: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;

        // create short lead
        this.http.get("https://ipinfo.io/json", {timeout: 2000}).then(function (response) {
            // resolve the promise
            deferred.resolve(response);
        }, function errorCallback(response) {
            console.log(response);
            deferred.resolve({ data: { ip: 'info_ip_error' } });
        });

        //return the promise
        return promise;
    },


    validateDuplicatedLead: function (email, phone) {
        var deferred = this.q.defer();
        var promise = deferred.promise;

        this.restangular.one('lead/v2', email).one('', phone)
            .get().then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        // return the promise 
        return promise;
    },

    leadApply: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;

        // create lead in full lead
        this.restangular.all('/toptarjetasco/lead/'+lead.id+'/apply')
        .post(lead).then(function(response) {
            // resolve promise 
            deferred.resolve(response.plain());
        });
        //return the promise
        return promise;
    },

    checkBlacklist: function (number){
        var deferred = this.q.defer();
        var promise = deferred.promise;
    
        // Get the localities using the Zip Code
        this.restangular.one('/blacklist/v2/' + number + "?lender=amex").get().then(
          function (response) {
            deferred.resolve(response);
          }, function (response) {
            deferred.resolve(response);
          }
        );
    
        // return the promise
        return promise;
    },

    getSetting: function(params){
        var deferred = this.q.defer();
        var promise = deferred.promise;

         // Get key
         this.restangular.one(`toptarjetas/v2/setting?${params}`).get().then(
            function (response) {
              deferred.resolve(response);
            }, function (response) {
              deferred.resolve(response);
            }
          );
        //return the promise
        return promise;
    },

    sendSms: function(params){
        var deferred = this.q.defer();
        var promise = deferred.promise;
        // send sms
        this.restangular.all('/toptarjetas/v2/sendSms')
        .post(params).then(function(response) {
            // resolve promise 
            deferred.resolve(response.plain());
        });
        //return the promise
        return promise;
    },

    getLink: function(cid, afid, sid, params_extend){ 
        var deferred = this.q.defer();
        var promise = deferred.promise;
        let arrayParams = _.remove(_.map(params_extend, function(value, key){
            if(value != ''){
                return `${key}=${value}`
            }
        }), function(o) { return o !== undefined });
        let params = '';
        if(arrayParams.length > 0){
            params = _.join(arrayParams, '&');
        }
        const url = params != '' ? 'linktrust/v2/redirect?cid=' + cid + '&afid=' + afid + '&sid=' + sid + '&product=TOPTARJETASCO&' + params : 'linktrust/v2/redirect?cid=' + cid + '&afid=' + afid + '&sid=' + sid + '&product=TOPTARJETASCO';
        // Get key
         this.restangular.one(url).get().then(
            function (response) {
              deferred.resolve(response);
            }, function (response) {
              deferred.resolve(response);
            }
          );
        //return the promise
        return promise;
    },
    getAffilate: function(afid){
        var deferred = this.q.defer();
        var promise = deferred.promise;

         // Get key
         this.restangular.one(`affiliate?afid=${afid}`).get().then(
            function (response) {
              deferred.resolve(response);
            }, function (response) {
              deferred.resolve(response);
            }
          );
        //return the promise
        return promise;
    },

}