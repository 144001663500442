angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('app', {
      url: '/?utm_source&utm_medium&utm_campaign&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&force&gclid&t_num&t_id&t_email&origin&sm_datetime',
      component: 'mainSection',
    })
    .state('faq', {
      url: '/faq',
      component: 'faqComponent'
    })
    .state('afiliados', {
      url: '/afiliados',
      component: 'afiliadosComponent' 
    })
    .state('step1', {
      url: '/step1',
      component: 'step1Component'
    })
    .state('stepE', {
      url: '/stepE',
      component: 'stepEComponent'
    })
    .state('step2', {
      url: '/step2',
      component: 'step3Component'
    })
    // .state('step3', {
    //   url: '/step3',
    //   component: 'step3Component'
    // })
    .state('step3', {
      url: '/step3',
      component: 'step4Component'
    })
    .state('step4', {
      url: '/step4',
      component: 'step5Component'
    })
    .state('thankyou', {
      url: '/gracias',
      component: 'thankyouComponent',
      params: {
        redirect: "",
        lender: ""
      }
    })
    .state('terms', {
      url: '/terms_and_conditions',
      templateUrl: 'app/components/legal/terms_and_conditions.html'
    })
    .state('notice_privacity', {
      url: '/notice_of_privacy',
      templateUrl: 'app/components/legal/notice_of_privacy.html'
    })
    .state('contact', {
      url: '/contact',
      component: 'contactComponent'
    })
    .state('mejores_tarjetas', {
      url: '/mejores_tarjetas',
      templateUrl: 'app/components/articles/mejores_tarjetas.html'
    })
    .state('tarjetas_sin_anualidad', {
      url: '/tarjetas_sin_anualidad',
      templateUrl: 'app/components/articles/tarjetas_sin_anualidad.html'
    })
    .state('tarjetas_estudiantes', {
      url: '/tarjetas_estudiantes',
      templateUrl: 'app/components/articles/tarjetas_estudiantes.html'
    })
    .state('tarjetas_sin_buro', {
      url: '/tarjetas_sin_buro',
      templateUrl: 'app/components/articles/tarjetas_sin_buro.html'
    })
    .state('tarjetas_sin_comprobar', {
      url: '/tarjetas_sin_comprobar',
      templateUrl: 'app/components/articles/tarjetas_sin_comprobar.html'
    })
    .state('tarjetas_jovenes', {
      url: '/tarjetas_jovenes',
      templateUrl: 'app/components/articles/tarjetas_jovenes.html'
    })
    .state('tarjetas_bajos_requisitos', {
      url: '/tarjetas_bajos_requisitos',
      templateUrl: 'app/components/articles/tarjetas_bajos_requisitos.html'
    })
    .state('tarjetas_sin_historial', {
      url: '/tarjetas_sin_historial',
      templateUrl: 'app/components/articles/tarjetas_sin_historial.html'
    })
    .state('tarjetas_viajes', {
      url: '/tarjetas_viajes',
      templateUrl: 'app/components/articles/tarjetas_viajes.html'
    })
    .state('tarjetas_departamentales', {
      url: '/tarjetas_departamentales',
      templateUrl: 'app/components/articles/tarjetas_departamentales.html'
    })
    .state('tarjetas_mastercard', {
      url: '/tarjetas_mastercard',
      templateUrl: 'app/components/articles/tarjetas_mastercard.html'
    })
    .state('tarjetas_visa', {
      url: '/tarjetas_visa',
      templateUrl: 'app/components/articles/tarjetas_visa.html'
    })
    .state('tarjetas_amex', {
      url: '/tarjetas_amex',
      templateUrl: 'app/components/articles/tarjetas_amex.html'
    })
    .state('tarjetas_garantizadas', {
      url: '/tarjetas_garantizadas',
      templateUrl: 'app/components/articles/tarjetas_garantizadas.html'
    })
    .state('mi_primera_tarjeta', {
      url: '/mi_primera_tarjeta',
      templateUrl: 'app/components/articles/mi_primera_tarjeta.html'
    })
    .state('tarjetas_platinum', {
      url: '/tarjetas_platinum',
      templateUrl: 'app/components/articles/tarjetas_platinum.html'
    })
    .state('tarjetas_credito_en_linea', {
      url: '/tarjetas_credito_en_linea',
      templateUrl: 'app/components/articles/tarjetas_credito_en_linea.html'
    })
    .state('salir_de_deudas', {
      url: '/salir_de_deudas',
      templateUrl: 'app/components/articles/salir_de_deudas.html'
    })
    .state('avantel', {
      url: '/avantel?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid',
      component: 'avantelProductComponent'
    })
    .state('gruporecordar', {
      url: '/gruporecordar?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid',
      component: 'grupoRecordarProductComponent'
    })
    .state('adiosdeudaco', {
      url: '/adiosdeudaco?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid',
      component: 'adiosDeudaCoProductComponent'
    })
    .state('preferencial', {
      url: '/preferencial?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&bbdd',
      component: 'preferencialProductComponent'
    })
    .state('famimas', {
      url: '/famimas?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&bbdd',
      component: 'famimasProductComponent'
    })
    .state('tuya', {
      url: '/tuya?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&bbdd',
      component: 'tuyaProductComponent'
    })
} 
