angular
    .module('app')
    .component('step5Component', {
        templateUrl: 'app/components/wizard/step5.component.html',
        controller: Step5
    });

/** @ngInject */
function Step5($rootScope, wizardService, $state, $window, $scope) {
    this.rs = $rootScope;
    this.message = '';
    this.state = $state;
    this.showSpinner = false;
    this.wizardService = wizardService;
    this.window = $window;
    // this.day = '';
    // this.month = '';
    // this.year = '';
    if (!this.rs.lead.id || this.rs.lead.status === 'COMPLETE') {
        this.state.go('app');
    }
    // this.window.fbq('track', 'ViewContent');
    let currentDate = new Date();
    // let years = [];
    // let minYear = currentDate.getFullYear() - 60; 
    // this.mYear = minYear;
    // for (let i = currentDate.getFullYear(); i >= minYear; i--) {
    //     years.push(i);
    // }
    // $scope.years = years;
}

Step5.prototype = {
    validateInfo: function() {
        var self = this;

        if (self.message) {
            self.message = '';
        }

        // validate all wizard fields
        // if (self.rs.lead.email == undefined ||
        //     self.rs.lead.email == '' ||
        //     self.rs.lead.mobile_number == undefined ||
        //     self.rs.lead.mobile_number == '' ||
        //     self.rs.lead.monthly_salary == undefined ||
        //     self.rs.lead.monthly_salary == '' ||
        //     self.rs.lead.interest == undefined ||
        //     self.rs.lead.interest == '' ||
        //     self.rs.lead.first_name == undefined ||
        //     self.rs.lead.first_name == '' ||
        //     self.rs.lead.last_name == undefined ||
        //     self.rs.lead.last_name == '' ||
        //     self.rs.lead.second_last_name == undefined ||
        //     self.rs.lead.second_last_name == '' ||
        //     self.day == '' ||
        //     self.month == '' ||
        //     self.year == '' || 
        //     self.has_credit_card == '') {
        if(self.has_credit_card == '' || self.has_credit_card == ''){
            self.message = ' Favor de llenar la informaci&oacute;n solicitada anteriormente<br>'
            return;
        } else {
            // if(parseInt(self.year) < parseInt(self.mYear)){
            //     if(parseInt(self.year) >= 1900){
            //         self.rs.lead.birthdate = new Date('2000', self.month - 1, self.day);
            //     }else if(parseInt(self.year) < 1900 && parseInt(self.year) > (parseInt(self.mYear)-100)){
            //         self.rs.lead.birthdate = new Date(self.year.replace('18','19'), self.month - 1, self.day);
            //     }else{
            //         self.rs.lead.birthdate = new Date(self.mYear, self.month - 1, self.day);
            //     }
            // }else{
            //     self.rs.lead.birthdate = new Date(self.year, self.month - 1, self.day);
            // }
            if(self.has_bank_account == 1 && (self.bank == null || self.bank == '')){
                self.message = ' Favor de llenar la informaci&oacute;n solicitada anteriormente<br>'
                return;
            }
            self.rs.lead.has_credit_card = parseInt(self.has_credit_card,10);
            self.rs.lead.has_bank_account = parseInt(self.has_bank_account,10);
            self.showSpinner = true;
            const ms = self.rs.lead.monthly_salary;
            switch (ms) {
                case 'sin_ingresos':
                    self.rs.lead.monthly_salary = 0;
                    break;
                case 'menos_de_500.000':
                    self.rs.lead.monthly_salary = 400000;
                    break;
                case 'entre_500.000_y_1.000.000':
                    self.rs.lead.monthly_salary = 600000;
                    break;
                case 'entre_1.000.000_y_1.755.000':
                    self.rs.lead.monthly_salary = 1200000;
                    break;
                case 'entre_1.755.000_y_3.000.000':
                    self.rs.lead.monthly_salary = 1800000;
                    break;
                case 'mas_de_3.000.000':
                    self.rs.lead.monthly_salary = 4000000;
                    break;
            }
            self.rs.lead.createdAt = new Date();
            self.rs.lead.updatedAt = new Date();
            self.rs.lead.last_step = 4;
            // self.rs.lead.first_name = self.rs.lead.first_name.replace(/\d+/, "");
            // self.rs.lead.last_name = self.rs.lead.last_name.replace(/\d+/, "");
            // self.rs.lead.second_last_name = self.rs.lead.second_last_name.replace(/\d+/, "");
            // self.rs.lead.first_name = self.rs.lead.first_name.replace(/,/g, "");
            // self.rs.lead.last_name = self.rs.lead.last_name.replace(/,/g, "");
            // self.rs.lead.second_last_name = self.rs.lead.second_last_name.replace(/,/g, "");
            self.rs.lead.status = 'COMPLETE';
            self.rs.lead.monthly_salary_selected = self.rs.lead.monthly_salary;
            // update shortLead to fullLead
            self.wizardService.updateLead(self.rs.lead)
            .then(function(data) {
                if(self.rs.lead.has_bank_account == 1)
                    self.rs.lead.bank = self.bank;
                self.wizardService.leadApply(self.rs.lead)
                .then(function(resp){
                    if(resp.status == 'OK'){
                        if(resp.details.lender.name == 'TUYA' && self.rs.lead.bank != 'otro'){
                            self.wizardService.getLink('434374', self.rs.lead.utm_source, self.rs.lead.sid, self.rs.params_extend)
                            .then(function(redirect){
                                self.state.go('thankyou', {
                                    redirect: redirect.detail,
                                    lender: 'Tuya'
                                })
                            })
                        }else{
                            if(self.rs.lead.monthly_salary > 1000000){ // Rapicredit
                                self.wizardService.getLink('431738', self.rs.lead.utm_source, self.rs.lead.sid, self.rs.params_extend)
                                .then(function(redirect){
                                    self.state.go('thankyou', {
                                        redirect: redirect.detail,
                                        lender: 'RapiCredit'
                                    })
                                })
                            }else{ // Lineru
                                self.wizardService.getLink('431739', self.rs.lead.utm_source, self.rs.lead.sid, self.rs.params_extend)
                                .then(function(redirect){
                                    self.state.go('thankyou', {
                                        redirect: redirect.detail,
                                        lender: 'Lineru'
                                    })
                                })
                            }
                        }
                    } else {
                        if(self.rs.lead.monthly_salary > 1000000){ // Rapicredit
                            self.wizardService.getLink('431738', self.rs.lead.utm_source, self.rs.lead.sid, self.rs.params_extend)
                            .then(function(redirect){
                                self.state.go('thankyou', {
                                    redirect: redirect.detail,
                                    lender: 'RapiCredit'
                                })
                            })
                        }else{ // Lineru
                            self.wizardService.getLink('431739', self.rs.lead.utm_source, self.rs.lead.sid, self.rs.params_extend)
                            .then(function(redirect){
                                self.state.go('thankyou', {
                                    redirect: redirect.detail,
                                    lender: 'Lineru'
                                })
                            })
                        }
                    }
                })
            });
        }
    }
};